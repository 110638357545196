// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:c337abf1-36e0-4707-b388-7f95c6b9aa9f",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_Abl3p0ym1",
    "aws_user_pools_web_client_id": "79sidccaonfhv79tais81dj46k",
    "aws_appsync_graphqlEndpoint": "https://q5s6a4rze5egdf7dvtosi7ca4m.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_user_files_s3_bucket": "photoalbums9020cc49529e4cc387eb7291edb81763",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "aws_content_delivery_bucket": "photoalbums-20190131190410-hostingbucket",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "http://photoalbums-20190131190410-hostingbucket.s3-website-us-east-1.amazonaws.com"
};


export default awsmobile;
